import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-container'></div>
       <p className='foot'> ©2023 APBS Bartender Guide</p>
    </div>
  )
}

export default Footer;