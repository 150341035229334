import React from "react";
import "./Link.css";

const Link07 = () => {
  return (
    <div className="links">
      <div className="container">
        <div className="column">
          <h3 className="perfect" style={{ marginTop: "156px" }}>
            Daily Bar Operations(DDS)
          </h3>

          <br />
        </div>

        <div className="cardstart" style={{ marginTop: "30px" }}>
          <div className="cardf">
            <div className="cardf-image-container">
              <img width="50%" height="50%" src="link7new.PNG" alt="pic" />
            </div>
            <div className="cardf-content">
              <h2 className="cardf-title"></h2>
              <h5 className="cardf-description">
                {" "}
                <b>Step 1: </b> Adopt a First In First Out method when using
                your kegs. The kegs with an earlier date of manufacturing/date
                of expiry should be used first
              </h5>
            </div>
          </div>

          <div className="carde" style={{ marginTop: "4%" }}>
            <div className="container" style={{ marginTop: "2%" }}>
              <div className="row">
                <div className="col-md-12">
                  <img
                    src="link2new.PNG"
                    alt="piccs"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                {/* <div className="col-md-6">
                  <img
                    src="pic22.jpg"
                    alt="piccs"
                    style={{ maxWidth: "100%" }}
                  />
                </div> */}
              </div>
            </div>
            <div className="carde-content">
              <h2 className="carde-title"></h2>
              <h5 className="carde-description">
                {" "}
                <b>Step 2: </b>Check the servicing card for your chillers to see
                if they have missed servicing or not.(IBC:15 days, DDS:3 months)
              </h5>
            </div>
          </div>

          <div className="carde" style={{ marginTop: "4%" }}>
            <div className="container" style={{ marginTop: "2%" }}>
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="pic3.jpeg"
                    alt="picccs"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                {/* <div className="col-md-6"  >
          <video  controls poster="link35a.webp" style={{maxWidth:"100%",minWidth:"96%"}}>
            <source  src="link35a.mp4" type="video/mp4"  />
          </video>
        </div> */}
              </div>
            </div>
            <div className="carde-content">
              <h2 className="carde-title"></h2>
              <h5 className="carde-description">
                <b>Step 3: </b>Ensure that the beer kegs are chilled for at
                least 12 hours before use.
              </h5>
            </div>
          </div>

          <div className="cardf" style={{ marginTop: "4%" }}>
            <div
              className="cardf-image-container"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <video
                width={"100%"}
                height={"100%"}
                controls
                poster="link7.4.jpeg"
              >
                <source src="link7.4.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="cardf-content">
              <h2 className="cardf-title"></h2>
              <h5 className="cardf-description">
                <b>Step 4: </b>Check the gas cylinder if there is sufficient
                volume in the cylinder. If the gas cylinder is depleted, the keg
                coupler will not be activated.
              </h5>
            </div>
          </div>

          <div className="cardd-content">
            <h2 className="cardff-title">Step 5 : Connect the beer keg</h2>

            <div className="container" style={{ marginTop: "2%" }}>
              <div className="row">
                <div>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="link35a.webp"
                  >
                    <source src="link35a.mp4" type="video/mp4" />
                  </video>
                </div>
                <br />
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b>Step 5.1:</b> Slot the beer line up the font
                  </h5>
                </div>
              </div>
              {/* <div className="cardff-substep"> */}
              <div className="row">
                <div className="videoinrow">
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="link36a.webp"
                  >
                    <source src="link36a.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b> Step 5.2:</b> Secure the beer line in place before
                    closing the font
                  </h5>
                </div>
              </div>
              {/* <div className="cardff-substep" > */}
              <div className="row">
                <div>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="link37a.webp"
                  >
                    <source src="link37a.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b> Step 5.3:</b> Slot in the coupler and engage the button
                    to enable gas flow
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="cardd-content" style={{ marginTop: "4%" }}>
            <h2 className="cardff-title">Step 6 : Clean the beer tap</h2>

            <div className="container" style={{ marginTop: "2%" }}>
              <div className="row">
                <div>
                  <img
                    src="step6new.PNG"
                    alt="piccs"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b> Step 6.1:</b> Prepare a brush and a pail of water. Soak
                    the brush into the water.
                  </h5>
                </div>
              </div>
              <div className="row">
                <div>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="images/link6.2new.jpeg"
                  >
                    <source src="link6.2new.mp4" type="video/mp4" />
                  </video>
                </div>
                <div>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="images/link6.2.1new.jpeg"
                  >
                    <source src="link6.2.1new.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b>Step 6.2:</b> Scrub the tap thoroughly with the brush and
                    then wipe the tap dry.
                  </h5>
                </div>
              </div>

              <div className="row">
                <div>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    poster="link8.5.3.jpeg"
                  >
                    <source src="link8.5.3.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="cardf-content">
                  <h5 className="cardff-substep-title">
                    <b> Step 6.3:</b> Turn on the tap to flush out any residue.
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cardf"
            style={{
              flexDirection: "column",
              alignItems: "center",
              marginTop: "4%",
            }}
          >
            <div className="cardf-content">
              <h2 className="cardf-title">Step 7 : Ensure clean glassware</h2>
              <h5 className="cardf-description"></h5>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6" style={{ marginTop: "1%" }}>
                  <img src="pic61.jpg" alt="pics" />
                </div>
                <div className="col-md-6" style={{ marginTop: "1%" }}>
                  <img src="pic62.jpg" alt="pics" />
                </div>
                <div className="col-md-6" style={{ marginTop: "1%" }}>
                  <img src="pic63.jpg" alt="pics" />
                </div>
                <div className="col-md-6" style={{ marginTop: "1%" }}>
                  <img src="pic64.jpg" alt="pics" />
                </div>
              </div>
            </div>
            <h5>Examples of glasses that are not clean</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Link07;
