import React from 'react'
import Barcontent from './Barcontent';

const Barprocesses = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Barcontent />
   </div>
  )
}

export default Barprocesses;